import React from 'react';

import ChromeRating from 'core/modules/Partials/ChromeRating/ChromeRating';

function ButtonScrollingModalContent({
  redirectToChromeStore, renderedLogos, storeCount, buttonContentRef,
}) {
  return (
    <div className="mn_buttonContent" ref={buttonContentRef}>
      <div className="mn_buttonBlock mn_buttonBlockOne">
        <div className="mn_motto">The SkyMiles Shopping Button</div>
        <h2 id="mn_dialogTitle">Earning Miles Couldn't Be Easier</h2>
        <p id="mn_dialogDescription">
          On average, members who use the SkyMiles<sup>&reg;</sup> Shopping button browser<br />
          extension earn <strong>twice as many miles</strong> as members who do not use the button.
        </p>
        <button className="mn_button" data-number="1" onClick={redirectToChromeStore}>Add to browser</button>
        <ChromeRating chromeText="Chrome Web Store Rating" />
      </div>
      <div className="mn_buttonBlock mn_buttonBlockTwo">
        <div className="mn_buttonOverlayMerchantLogosWrap">
          <div className="mn_buttonOverlayMerchantLogos">
            {renderedLogos}
          </div>
        </div>
        <div className="mn_motto">Never miss a mile with the button</div>
        <h2>Earn Miles At {storeCount}+ Stores With the Button</h2>
        <p>
          Notifications and automatic coupon alerts through the SkyMiles Shopping button<br />
          help you save money and earn miles on the shopping you're already doing.
        </p>
        <button className="mn_button" data-number="2" onClick={redirectToChromeStore}>Discover the button</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockThree">
        <h2>How It Works</h2>
        <div className="mn_hiwHints">
          <div>
            <h3>Install in Seconds</h3>
            <p>Download the free browser extension in just two clicks.</p>
          </div>
          <div>
            <h3>Shop as Usual</h3>
            <p>
              Shop at {storeCount}+ stores &#8212; the button will notify you when miles are available.
            </p>
          </div>
          <div>
            <h3>Earn Miles</h3>
            <p>Click the notification to activate your earnings and rack up Delta SkyMiles.</p>
          </div>
        </div>
        <button className="mn_button" data-number="3" onClick={redirectToChromeStore}>Install now</button>
      </div>

      <div className="mn_buttonBlock mn_buttonBlockFour">
        <h2>Get The Best Price & The Most Miles</h2>
        <p>
          Get notified to activate miles while you shop and receive alerts when a lower price is available.<br />
          Plus, the button automatically applies coupons directly to your cart.
        </p>
        <button className="mn_button" data-number="4" onClick={redirectToChromeStore}>Start earning more</button>
        <ChromeRating chromeText="Chrome Web Store Rating" />
      </div>
    </div>
  );
}

export default ButtonScrollingModalContent;
